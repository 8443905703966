import React, { useState, useEffect ,ChangeEvent } from 'react';
import logo from './logo.svg';
import { Input, Button  } from '@nextui-org/react';
import './App.css';
import SearchBar from './components/SearchBar';
import QnAList from './components/QnAList';
import { qnaData, QnA } from './data';


const App: React.FC = () => {
  const [keyword, setKeyword] = useState<string>('');
  const [filteredQnA, setFilteredQnA] = useState<QnA[]>(qnaData);
  const [initialQnA, setInitialQnA] = useState<QnA[]>([]);

  useEffect(() => {
    // 初始化显示前10条数据
    setInitialQnA(qnaData.slice(0, 10));
    setFilteredQnA(qnaData.slice(0, 10));
  }, []);

  useEffect(() => {
    handleSearch(keyword);
  }, [keyword]);

  const handleSearch = (keyword: string) => {
    setKeyword(keyword);
    if (keyword === '') {
      setFilteredQnA(qnaData.slice(0, 10));
    } else {
      const filtered = qnaData.filter(qna =>
        qna.Question.includes(keyword) || qna.Answer.includes(keyword)
      );
      setFilteredQnA(filtered);
    }
  };

  const handleClear = () => {
    setKeyword('');
    setFilteredQnA(qnaData.slice(0, 10));
  };
  
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  return (
    <div className="container">
      <h1 className="title">学习强国 趣味问答搜索</h1>
      <div className="input-container">
        <Input
          fullWidth
          placeholder="请输入问题或答案关键词..."
          value={keyword}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
        />
        <Button onClick={handleClear} className="clear-button">
          清空
        </Button>
      </div>
      <div className="spacer" />
      <QnAList qnas={filteredQnA} />
      <Button onClick={scrollToTop} className="scroll-to-top-button">
        回到顶部
      </Button>
    </div>
  );
};

export default App;
