import React from 'react';

interface QnA {
    Question: string;
    Answer: string;
}

interface QnAListProps {
  qnas: QnA[];
}

const QnAList: React.FC<QnAListProps> = ({ qnas }) => {
  return (
    <ul>
      {qnas.map((qna, index) => (
        <li key={index}>
          <strong>问题{index + 1} : {qna.Question}</strong>
          <p>答案 : {qna.Answer}</p>
        </li>
      ))}
    </ul>
  );
};

export default QnAList;
